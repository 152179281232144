import React from 'react';
import dayjs from 'dayjs';
import { Alert } from '../../../../types/alert';
import { TriggerEntityType } from '../../../../types/trigger';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from './BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { FilterProps } from '../filter.util';

export class AlertFilter extends BaseFilter<Alert> {
  toLabel(): string {
    return t().alert.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: Alert, textFilter: string) {
    if (toFilterString(item.alertString).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: Alert[], query: string, filterProps: FilterProps) {
    const result = items.filter(item => {
      if (filterProps.stockLocation?.size && !item.stockLocationIds.some(sl => filterProps.stockLocation?.has(sl)))
        return false;
      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filterProps.product?.size) {
        if (item.entityType !== TriggerEntityType.pmd) return false;
        return filterProps.product.has(item.triggeredValue);
      }

      if (filterProps.lot?.size) {
        if (item.entityType !== TriggerEntityType.lot) return false;
        return filterProps.lot.has(item.triggeredValue);
      }

      return this.search(item, query);
    });

    return result;
  }
}
