import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { UpdateUserResponse, UpdateUserVariables, UserMutations } from '../../../../graphql/user.graphql';
import { User } from '../../../../types/user';
import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';
import {
  LanguageRegionDesignator,
  isValidLanguageRegionDesignator,
  storageStringToLanguageRegionDesignator,
} from '../../../../types/common/languageRegionDesignator';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { CancelButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { SaveButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import UploadFileInput from '../../../../VentoryUI/components/common/File/UploadFileInput';
import { CreateFileInput, FileEntity, FileEntityType } from '../../../../types/file';
import { CreateFilesResponse, CreateFilesVariables, FileMutations } from '../../../../graphql/file.graphql';
import { FileContext } from '../../../../context/FileContext';
import ProfileImage from '../../../../VentoryUI/components/common/File/Image/ProfileImageViewLoader';
import { t } from '../../../../types/translation/Translator';
import { TranslationKey } from '../../../../types/translation/TranslationKey';

interface UpdateProfileModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function UpdateProfileModal({ open, setOpen }: UpdateProfileModalInputProps) {
  if (!open) return null;

  const { currentUser, setCurrentUser, signOut } = useContext(UserContext);
  const { files, setFiles } = useContext(FileContext);

  const [userInput, setUserInput] = useState(currentUser || new User({}));

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateFilesResponse, CreateFilesVariables>(FileMutations.create, {
    onCompleted: result => {
      result.createFiles.forEach(file => files.set(file.id, new FileEntity(file)));
      setFiles(new Map(files));
    },
    onError: error => setError(error.message),
  });

  const [update, { loading: updateLoading }] = useMutation<UpdateUserResponse, UpdateUserVariables>(
    UserMutations.update,
  );

  const handleUpdate = async () => {
    try {
      await update({
        variables: {
          user: userInput,
        },
        onCompleted: res => {
          signOut();
        },
        onError: res => setError(res.message),
      });
    } catch (e) {
      setError(String(e));
    }
    handleClose();
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const handleFile = async (files: FileList | null) => {
    if (!files?.length) {
      setUserInput(userInput.withProfilePicture(''));
    } else {
      const file = files[0];
      CreateFileInput.fromFile(
        file,
        userInput.id,
        FileEntityType.user,
        '00000000-0000-0000-0000-000000000000',
        async result => {
          const response = await create({ variables: { files: [result] } });
          const fileId = response.data?.createFiles[0].id;
          if (!fileId) return setError('Something went wrong uploading your file, please try again later!');
          await update({
            variables: { user: userInput.withProfilePicture(fileId) },
            onCompleted: response => {
              setUserInput(new User(response.updateUser));
              setCurrentUser(new User(response.updateUser));
            },
            onError: error => setError(error.message),
          });
        },
      );
    }
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().updateProfile.singular.label}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: updateLoading }),
          SaveButtonTemplate(handleUpdate, { loading: updateLoading }),
        ]}
      >
        <Grid container rowSpacing={1} alignContent={'space-between'}>
          <Grid item xs={12} className='bg-yellow-100 h3 py-3 px-4 border-2 border-yellow-200'>
            <p style={{ textAlign: 'center' }} className='font-semibold text-yellow-500 text-sm'>
              {t().updatingYourProfileWillSignYouOut.singular.label}
            </p>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t().firstName.singular.label}
                  placeholder={t().firstName.singular.label}
                  value={userInput.firstName}
                  onChange={v => (userInput.firstName = v)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t().lastName.singular.label}
                  placeholder={t().lastName.singular.label}
                  value={userInput.lastName}
                  onChange={v => (userInput.lastName = v)}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect<LanguageRegionDesignator>
                  label={t().language.singular.label}
                  values={Object.values(LanguageRegionDesignator).filter(isValidLanguageRegionDesignator)}
                  selectedValue={storageStringToLanguageRegionDesignator(userInput.language) || null}
                  toText={value => {
                    return t()[value as unknown as TranslationKey]?.singular.label;
                  }}
                  onChange={value => {
                    setUserInput(userInput.withLanguage(value));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadFileInput
                  loading={loading || updateLoading}
                  height='240px'
                  acceptType='image'
                  file={userInput.profilePicture}
                  onFile={handleFile}
                  fileDisplay={<ProfileImage fileId={userInput.profilePicture || ''} displayPlaceholder={false} />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
