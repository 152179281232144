import { Grid } from '@mui/material';
import TextInput from '../../../../../../Common/TextInput';
import React, { useContext } from 'react';
import { IntegrationSettings } from '../../../../../../../types/integrationSettings';
import { t } from '../../../../../../../types/translation/Translator';
import Paper from '../../../../../../../VentoryUI/components/common/Paper/Paper';
import { useLazyQuery } from '@apollo/client';
import {
  SAPQueries,
  SAPSyncMaterialMasterResponse,
  SAPSyncMaterialMasterVariables,
  SAPSyncStorageLocationResponse,
  SAPSyncStorageLocationVariables,
} from '../../../../../../../graphql/sap.graphql';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { Button } from '../../../../../../../VentoryUI/components/common/Button/Button';

interface IntegrationSAPSettingsInfoProps {
  settingsInput: IntegrationSettings;
  setSettingsInput: (settings: IntegrationSettings) => void;
  disabled: boolean;
  setError: (error: string) => void;
}

export default function IntegrationSAPSettingsInfo({
  settingsInput,
  setSettingsInput,
  disabled,
  setError,
}: IntegrationSAPSettingsInfoProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [syncMaterialMaster, { loading: syncMaterialMasterLoading }] = useLazyQuery<
    SAPSyncMaterialMasterResponse,
    SAPSyncMaterialMasterVariables
  >(SAPQueries.syncMaterialMaster, {
    variables: {
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      setError('');
    },
    onError: err => {
      setError(err.message);
    },
  });

  const [syncStorageLocation, { loading: syncStorageLocationLoading }] = useLazyQuery<
    SAPSyncStorageLocationResponse,
    SAPSyncStorageLocationVariables
  >(SAPQueries.syncStorageLocation, {
    variables: {
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      setError('');
    },
    onError: err => {
      setError(err.message);
    },
  });

  return (
    <Paper>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6}>
          <TextInput
            disabled={disabled}
            mandatory
            value={settingsInput.name}
            onChange={v => setSettingsInput(settingsInput.withName(v))}
            placeholder={t().name.singular.label}
            label={t().name.singular.label}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            mandatory
            disabled={disabled}
            value={settingsInput.plantId}
            onChange={v => setSettingsInput(settingsInput.withPlantId(v))}
            placeholder={'Plant ID'}
            label={'Plant ID'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            mandatory
            disabled={disabled}
            value={settingsInput.url}
            onChange={v => setSettingsInput(settingsInput.withUrl(v))}
            placeholder={t().urlInput.singular.label}
            label={t().urlInput.singular.label}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            mandatory
            disabled={disabled}
            type={disabled ? 'password' : 'text'}
            value={settingsInput.apiKey}
            onChange={v => setSettingsInput(settingsInput.withApiKey(v))}
            placeholder={t().apiKey.singular.label}
            label={t().apiKey.singular.label}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <Button
                startIcon={<CloudSyncIcon />}
                onClick={() => syncMaterialMaster()}
                loading={syncMaterialMasterLoading}
                text={t().syncMaterialMaster.singular.label}
              />
            </Grid>
            <Grid item>
              <Button
                startIcon={<CloudSyncIcon />}
                onClick={() => syncStorageLocation()}
                loading={syncStorageLocationLoading}
                text={t().syncStorageLocation.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
