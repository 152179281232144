import React from 'react';
import { t } from '../../../../types/translation/Translator';
import { GlobeIcon } from '../../../icons/Globe/GlobeIcon';
import { Country } from '../../../../types/common/address';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import CountryFilterDropdownContent from '../DynamicEntityFilter/Content/CountryFilterContent';
import CountryFilterInnerContent from '../DynamicEntityFilter/InnerContent/CountryFilterInnerContent';

export class CountryFilter extends BaseFilter<Country> {
  toLabel(): string {
    return t().country.singular.label;
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <CountryFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <CountryFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toIcon(props: BaseFilterProps): React.JSX.Element {
    return <GlobeIcon className={this.className} />;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.country?.size || 0;
  }
}
