import Color from 'color';
import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { Colors, VentoryColor } from '../../../util/color.util';

export interface BadgeProps {
  content?: number;
  backgroundColor?: string;
}

export default function Badge({ content, backgroundColor }: BadgeProps) {
  if (!content) return null;

  const { currentCompany } = useContext(CompanyContext);

  backgroundColor ??= Colors.getPrimaryColor(currentCompany);

  const textColor = new Color(backgroundColor).isDark() ? VentoryColor.white : VentoryColor.black;
  const rightShift = content.toString().length - 1;

  const width = useMemo(() => {
    let min = 16;
    if (content) min += (content.toString().length - 1) * 7;
    return `${min}px`;
  }, [content]);

  const pane = document.getElementsByClassName('pane')[0] as HTMLDivElement;

  if (!pane) return null;

  // Display a fixed position (ignores overflow rules) badge on a non scrollable pane
  if (pane.style.overflow !== 'auto' && pane.style.overflow !== 'scroll') {
    return (
      <div
        className={`absolute top-[2px] text-[12px]`}
        style={{
          right: `-${rightShift}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          lineHeight: '12px',
        }}
      >
        <div
          className='fixed rounded-full z-10 flex items-center justify-center'
          style={{ color: textColor, backgroundColor, height: '16px', width }}
        >
          {content}
        </div>
      </div>
    );
  }

  // Display an absolute positioned badge on scrollable panes (this can lead to overflow issues but is okay for now, can use OverflowBadge then)
  return (
    <div
      className={`absolute top-[-6px] right-[-8px] text-[12px] rounded-full flex items-center justify-center`}
      style={{ lineHeight: '12px', color: textColor, backgroundColor, height: '16px', width }}
    >
      {content}
    </div>
  );
}
