import { OrderStatus } from '../../../types/order';
import { TaskStatus } from '../../../types/task';

export enum FilterEntity {
  STOCK_LOCATION = 'STOCK_LOCATION',
  TASK = 'TASK',
  ORDER = 'ORDER',
  PRODUCT_TRANSACTION = 'PRODUCT_TRANSACTION',
  BIN_FOR_TASK = 'BIN_FOR_TASK',
  PRODUCT_FOR_TASK = 'PRODUCT_FOR_TASK',
  PRODUCT = 'PRODUCT',
  BIN = 'BIN',
  PRODUCT_MASTER_DATA = 'PRODUCT_MASTER_DATA',
  CONTACT = 'CONTACT',
  SHIPPING_LOCATION = 'SHIPPING_LOCATION',
  USER = 'USER',
  REORDER_RULE = 'REORDER_RULE',
  REORDER_USER_RULE = 'REORDER_USER_RULE',
  BIN_STATUS = 'BIN_STATUS',
  SCAN_CONFIGURATION = 'SCAN_CONFIGURATION',
  SCAN_RULE = 'SCAN_RULE',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  INTEGRATION_SETTINGS = 'INTEGRATION_SETTINGS',
  REORDER_RULE_PRODUCT = 'REORDER_RULE_PRODUCT',
  TRIGGER = 'TRIGGER',
  ALERT = 'ALERT',
  FILE = 'FILE',
  TRANSLATION = 'TRANSLATION',
}

export interface FilterParameters {
  type?: FilterEntity;
  startDate?: string;
  endDate?: string;
  taskStatus?: TaskStatus[];
  orderStatus?: OrderStatus[];
  stockLocationIds?: string[];
  country?: string[];
  binIds?: string[];
  productReferenceDataIds?: string[];
  userIds?: string[];
  additionalFilters?: string[];
}

export interface EntityTimeFrame {
  start: Date;
  end: Date;
}

export class FilterProps {
  timeFrame?: EntityTimeFrame;
  staticTimeFrame?: string;

  stockLocation?: Set<string>;

  taskStatus?: Set<string>;
  taskType?: Set<string>;

  orderStatus?: Set<string>;
  orderType?: Set<string>;

  country?: Set<string>;
  bin?: Set<string>;
  product?: Set<string>;
  user?: Set<string>;
  lot?: Set<string>;

  serialManaged?: boolean;
  lotManaged?: boolean;
  lpnManaged?: boolean;

  fileTypeCategory?: Set<string>;
  fileType?: Set<string>;

  constructor(timeFrame?: EntityTimeFrame, staticTimeFrame?: string) {
    this.timeFrame = timeFrame;
    this.staticTimeFrame = staticTimeFrame;
  }
}

export function getFilterCount(filter: FilterProps) {
  let count = 0;
  for (const value of Object.values(filter)) {
    if (value) count++;
  }

  return count;
}

export function getSaveFilter(entity: FilterEntity | undefined): string | null {
  if (!entity) return null;
  return localStorage.getItem(`filter_${entity.toString()}`);
}

export function storeSavedFilter(entity: FilterEntity, filter: string) {
  return localStorage.setItem(`filter_${entity.toString()}`, filter);
}

export function replacer(key: any, value: any) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()),
    };
  } else if (value instanceof Set) {
    return {
      dataType: 'Set',
      value: Array.from(value.values()),
    };
  } else {
    return value;
  }
}

export function reviver(key: any, value: any) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    } else if (value.dataType === 'Set') {
      return new Set(value.value);
    }
  }
  return value;
}
