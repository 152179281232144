import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CreateTaskInput, Task, TaskType } from '../../../../types/task';
import { BinContext } from '../../../../context/BinContext';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { ProductContext } from '../../../../context/ProductContext';
import ToggleButton from '../../../Common/ToggleButton';
import Selector from '../../../Common/Selector';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface CreateTaskEntityPaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next: () => void;
  back: () => void;
}

enum TaskEntityView {
  bin = 'bin',
  product = 'product',
}

export function CreateTaskEntityPane({ task, setTask, next, back }: CreateTaskEntityPaneInputProps) {
  const { bins } = useContext(BinContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { products } = useContext(ProductContext);

  const [view, setView] = useState<TaskEntityView>(TaskEntityView.bin);

  const binsWithProducts = new Set(
    [...products.values()]
      .map(v => [...v.values()])
      .flat()
      .map(p => p.binId),
  );

  return (
    <ModalPane
      overflow='auto'
      testId={testIds.createTaskEntityPane}
      footerButtons={[BackButtonTemplate(back), NextButtonTemplate(next, { disabled: !task.identifiers })]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item flexGrow={1} marginY={'auto'}>
              {task.type === TaskType.cycle ? (
                <p className='text-sm font-semibold'>{t().selectBinsOrProducts.singular.label}</p>
              ) : (
                <p className='text-sm font-semibold'>{t().selectBin.plural.label}</p>
              )}
            </Grid>
            {task.type === TaskType.cycle ? (
              <Grid item>
                <ToggleButton
                  testId={testIds.entity}
                  onChange={value => {
                    if (value == TaskEntityView.bin) {
                      task.withProductMasterDataIds([]);
                    } else {
                      task.withBinIds([]);
                    }
                    setView(value as TaskEntityView);
                  }}
                  selected={view}
                  values={Object.keys(TaskEntityView)}
                  text={value => (value === 'bin' ? 'Bin' : 'Products')}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {view === TaskEntityView.bin ? (
                <Selector
                  testId={testIds.bins}
                  key={'binItems'}
                  placeholder={t().filterBins.singular.label}
                  values={[...bins.values()]
                    .filter(
                      bin =>
                        bin.stockLocationId === task.stockLocationId &&
                        (binsWithProducts.has(bin.id) || task.type === TaskType.blind),
                    )
                    .map(bin => bin.id)}
                  checkedValues={task.binIds()}
                  toText={item => bins.get(item)?.name || 'Unknown bin'}
                  onChange={checked => setTask(task.withBinIds(checked))}
                />
              ) : task.type !== TaskType.blind ? (
                <Selector
                  testId={testIds.products}
                  key={'productItems'}
                  placeholder={t().filterProducts.singular.label}
                  values={[...products.keys()]}
                  checkedValues={task.productMasterDataIds()}
                  toText={item => productMasterData.get(item)?.productName || 'Unknown product'}
                  onChange={checked => setTask(task.withProductMasterDataIds(checked))}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
