import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../context/CompanyContext';
import { t } from '../../../types/translation/Translator';
import { ReactComponent as VentoryWhiteLogo } from '../../../assets/img/Ventory-WhiteNoBack.svg';
import { useNavigate } from 'react-router-dom';
import DeleteCompanyModal from './Modals/DeleteCompanyModal';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { Button } from '../../../VentoryUI/components/common/Button/Button';
import DeleteButton from '../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import FileViewLoader from '../../../VentoryUI/components/common/File/FileViewLoader';
import ImageView from '../../../VentoryUI/components/common/File/Image/ImageView';
import { Colors } from '../../../VentoryUI/util/color.util';
import { FileEntityType } from '../../../types/file';

interface CompanySettingsPaneInputProps {}

export default function CompanySettingsPane({}: CompanySettingsPaneInputProps) {
  const navigate = useNavigate();
  const { currentCompany } = useContext(CompanyContext);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  return (
    <>
      <DeleteCompanyModal open={openDeleteModal} setOpen={setOpenDeleteModal} />

      <Paper>
        <Grid container rowSpacing={2} alignContent={'start'} data-testid={testIds.companySettingsPane}>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} justifyContent={'flex-end'}>
              <Grid item flexGrow={1} marginY='auto'>
                <p className='text-lg font-bold'>{currentCompany.name}</p>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setOpenDeleteModal(true)} />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => navigate('/settings/advanced/custom_fields')}
                  text={t().advancedSettings.singular.label}
                />
              </Grid>

              <Grid item>
                <Button
                  onClick={() => navigate('/settings/company/update')}
                  testId={testIds.edit}
                  text={t().edit.singular.label}
                  style='secondary'
                />
              </Grid>
            </Grid>
          </Grid>
          {currentCompany.settings.companyLogo || !currentCompany.settings.companyFavicon ? (
            <Grid item xs={12}>
              <div>
                <p className='text-sm font-semibold text-slate-800 mb-2'>{t().companyLogo.singular.label}</p>
                <div
                  style={{ backgroundColor: Colors.getHeaderColor(currentCompany) }}
                  className='w-[260px] h-[64px] border border-gray-300 rounded-sm shadow-sm bg-blend-exclusion p-2 flex items-center justify-center'
                >
                  <div className='h-full'>
                    {currentCompany.settings.companyLogo ? (
                      <FileViewLoader
                        fileId={currentCompany.settings.companyLogo}
                        fileEntityType={FileEntityType.company}
                      />
                    ) : !currentCompany.settings.companyFavicon ? (
                      <VentoryWhiteLogo width={'210px'} height={'44px'} />
                    ) : null}
                  </div>
                </div>
              </div>
            </Grid>
          ) : null}
          {currentCompany.settings.companyFavicon || !currentCompany.settings.companyLogo ? (
            <Grid item xs={12}>
              <p className='text-sm font-semibold text-slate-800 mb-2'>{t().companyFavicon.singular.label}</p>

              <div
                style={{ maxHeight: '56px', backgroundColor: Colors.getHeaderColor(currentCompany) }}
                className='w-fit border border-gray-300 rounded-sm shadow-sm bg-blend-exclusion p-2'
              >
                <div className='h-[32px] w-[32px]'>
                  {currentCompany.settings.companyFavicon ? (
                    <FileViewLoader
                      fileId={currentCompany.settings.companyFavicon}
                      fileEntityType={FileEntityType.company}
                    />
                  ) : currentCompany.settings.companyLogo ? null : (
                    <ImageView url='https://ventorystoragedev.blob.core.windows.net/ventory/favicon.ico' />
                  )}
                </div>
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12} my={'auto'}>
            <p className='text-sm font-semibold text-slate-800 mb-2'>{t().companyColors.singular.label}</p>
            <div
              className='w-12 h-12 rounded-full border border-gray-300 inline-block my-auto'
              style={{ backgroundColor: Colors.getHeaderColor(currentCompany) }}
            ></div>
            <div
              className='w-12 h-12 rounded-full border border-gray-300 inline-block ml-4 my-auto'
              style={{ backgroundColor: Colors.getPrimaryColor(currentCompany) }}
            ></div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
