import { t } from '../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function NewButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.new,
    text: t().newText.singular.label,
    onClick: onClick,
    ...props,
  };
}

interface NewButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function NewButton({ onClick, ...props }: NewButtonProps) {
  return <Button {...NewButtonTemplate(onClick, props)} />;
}
