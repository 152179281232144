import React from 'react';
import { TranslationKey } from '../../../../types/translation/TranslationKey';
import { Translations } from '../../../../types/translation/common';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from './BaseFilter';

export class TranslationFilter extends BaseFilter<TranslationKey> {
  english: Translations;
  current: Translations;
  constructor(english: Translations, current: Translations = {} as Translations) {
    super();
    this.english = english;
    this.current = current;
  }
  toLabel(): string {
    return 'Not Implemented';
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(key: TranslationKey, textFilter: string) {
    const englishRecord = this.english[key];
    const currentRecord = this.current[key];

    if (englishRecord) {
      if (englishRecord.singular.lower.includes(textFilter)) return true;
      if (englishRecord.plural.lower.includes(textFilter)) return true;
    }

    if (currentRecord) {
      if (currentRecord.singular.lower.includes(textFilter)) return true;
      if (currentRecord.plural.lower.includes(textFilter)) return true;
    }

    if (toFilterString(key).includes(textFilter)) return true;

    return false;
  }
}
