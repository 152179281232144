import React from 'react';
import { taskStatusToString } from '../../../../../types/task';
import InnerFilterContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function TaskStatusFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<string>) {
  return (
    <InnerFilterContent
      filterProps={{ filterProps, setFilterProps }}
      filter={filter}
      items={[...(filterProps.taskStatus || [])]}
      text={item => taskStatusToString(item, item === 'overdue')}
      onRemove={i => {
        filterProps.taskStatus?.delete(i);
        if (!filterProps.taskStatus?.size) filterProps.taskStatus = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
