import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import InnerFilterContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';
import { ProductMasterData } from '../../../../../types/productMasterData';

export default function ProductMasterDataFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<ProductMasterData>) {
  const { productMasterData } = useContext(ProductMasterDataContext);

  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.product || [])]}
      text={item => productMasterData.get(item)?.productName || t().unknownProduct.singular.label}
      onRemove={i => {
        filterProps.product?.delete(i);
        if (!filterProps.product?.size) filterProps.product = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
