import React from 'react';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import { JSX } from 'react';
import { t } from '../../../../types/translation/Translator';
import { MessageQuestionCircleIcon } from '../../../icons/MessageQuestionCircle/MessageQuestionCircleIcon';
import { StockLocation } from '../../../../types/stockLocation';
import { OrderTableSettings } from '../../../../types/orderTableSettings';
import OrderTypeFilterContent from '../DynamicEntityFilter/Content/OrderTypeFilterContent';
import OrderTypeFilterInnerContent from '../DynamicEntityFilter/InnerContent/OrderTypeFilterInnerContent';

export class OrderTypeFilter extends BaseFilter<string> {
  filteredStockLocations: Map<string, StockLocation>;
  tableSettings: Map<string, OrderTableSettings>;
  constructor(filteredStockLocations: Map<string, StockLocation>, tableSettings: Map<string, OrderTableSettings>) {
    super();
    this.filteredStockLocations = filteredStockLocations;
    this.tableSettings = tableSettings;
  }
  toLabel(): string {
    return t().orderType.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.orderType?.size || 0;
  }

  toIcon(): JSX.Element {
    return <MessageQuestionCircleIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <OrderTypeFilterContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <OrderTypeFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }
}
