import React from 'react';
import { ProductMasterData } from '../../../../types/productMasterData';
import { t } from '../../../../types/translation/Translator';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from './BaseFilter';
import { ReorderRuleProduct } from '../../../../components/Common/ReorderRules/ReorderRuleScreen';

export class ReorderRuleProductFilter extends BaseFilter<ReorderRuleProduct> {
  productMasterData: Map<string, ProductMasterData>;
  constructor(productMasterData: Map<string, ProductMasterData>) {
    super();
    this.productMasterData = productMasterData;
  }
  toLabel(): string {
    return t().reorderRuleForProductStockLocationAndAggregateTypeAlreadyExists.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: ReorderRuleProduct, textFilter: string) {
    const productName = this.productMasterData.get(item.productMasterDataId)?.productName;
    const productNumber = this.productMasterData.get(item.productMasterDataId)?.productNumber;

    if (toFilterString(productName).includes(textFilter) || toFilterString(productNumber).includes(textFilter)) {
      return true;
    }
    return false;
  }
}
