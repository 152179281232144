import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import {
  CsvExportFile,
  ReportMutations,
  ReportProductMasterDataResponse,
  ReportProductMasterDataVariables,
} from '../../../../graphql/report.graphql';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { CompanyContext } from '../../../../context/CompanyContext';

import { ProductMasterDataExportConfigurationContext } from '../../../../context/ProductMasterDataExportConfigurationContext';
import ProductMasterDataExportConfigurationItem from '../../Settings/Panes/Export/Common/ProductMasterDataExportConfigurationItem';
import { zipAndDownloadExportFiles } from '../../../../util/export.util';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { CancelButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';

interface ExportProductMasterDataModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export function ExportProductMasterDataModal({ open, setOpen, ids }: ExportProductMasterDataModalProps) {
  const { productMasterDataExportConfigurations, productMasterDataExportConfigurationsLoading } = useContext(
    ProductMasterDataExportConfigurationContext,
  );
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');
  const [selected, setSelected] = useState<Set<string>>(
    new Set(
      [...productMasterDataExportConfigurations.values()].filter(config => config.default).map(config => config.id),
    ),
  );

  useEffect(() => {
    if (!productMasterDataExportConfigurationsLoading)
      setSelected(
        new Set(
          [...productMasterDataExportConfigurations.values()].filter(config => config.default).map(config => config.id),
        ),
      );
  }, [productMasterDataExportConfigurationsLoading]);

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const [download, { loading: downloadLoading }] = useMutation<
    ReportProductMasterDataResponse,
    ReportProductMasterDataVariables
  >(ReportMutations.productMasterData);

  const handleDownload = async () => {
    if (!ids.size) return;

    const files: CsvExportFile[] = [];
    let hasNext = true;
    let page = 0;

    while (hasNext) {
      const response = await download({
        variables: {
          productMasterDataIds: [...ids.values()],
          page: page,
          timezone: moment.tz.guess(),
          locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
          companyId: currentCompany.id,
          configurationIds: [...selected.values()],
        },
      });
      files.push(...(response.data?.generateProductMasterDataExport.data || []));

      hasNext = response.data?.generateProductMasterDataExport.hasNext || false;
      page++;
    }

    if (!files.length) return console.error('No files to download');
    zipAndDownloadExportFiles(
      files,
      productMasterDataExportConfigurations,
      `ventory_product_references${new Date().toISOString()}.zip`,
    );

    setSelected(new Set());
    handleClose();
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().exportProductReferences.singular.label}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: downloadLoading }),
          {
            text: t().export.singular.label,
            onClick: handleDownload,
            loading: downloadLoading,
            style: 'secondary',
          },
        ]}
      >
        <Grid container alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <p className='text-sm font-semibold'>
                  {t().pleaseSelectConfigurationsForProductReferenceExport.singular.label}
                </p>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Grid container maxHeight={'500px'} overflow={'auto'}>
                  {productMasterDataExportConfigurations.size ? (
                    [...productMasterDataExportConfigurations.values()].map(config => (
                      <Grid key={config.id} item>
                        <ProductMasterDataExportConfigurationItem
                          onClick={() => {
                            selected.has(config.id) ? selected.delete(config.id) : selected.add(config.id);
                            setSelected(new Set(selected));
                          }}
                          configuration={config}
                          key={config.id}
                          ids={selected}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid item className='w-40 h-40 p-1 inline-flex'>
                      <Grid
                        container
                        alignContent={'center'}
                        className={`w-full h-full cursor-pointer rounded-lg border`}
                      >
                        <Grid item xs={12} textAlign={'center'}>
                          {t().defaultText.singular.label}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
