import { useQuery } from '@apollo/client';
import React, { useContext, useMemo } from 'react';
import { useState } from 'react';
import { TranslationContext } from '../../../context/TranslationContext';
import {
  GetTranslationsResponse,
  GetTranslationsVariables,
  TranslationQueries,
} from '../../../graphql/translations/translation.graphql';
import { TranslationRecord, TranslationUtils } from '../../../types/translation/common';
import { Translation, TranslationLanguage } from '../../../types/translation/translation';
import { TranslationKey } from '../../../types/translation/TranslationKey';
import { english, t } from '../../../types/translation/Translator';
import DropdownSelect from '../../../VentoryUI/components/common/DropdownSelect/DropdownSelect';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import Table, { TableHeader } from '../../../VentoryUI/components/common/Table/Table';
import { TranslationFilter } from '../../../VentoryUI/components/filters/Filter/TranslationFilter';
import LoadingPackage from '../../Common/LoadingPackage';
import TranslationRecordModal from './Modals/TranslationRecordModal';
import Checkbox from '../../../VentoryUI/components/common/Checkbox/Checkbox';
import { SuperUserContext } from '../../../context/SuperUserContext';
import { SuperUserRole } from '../../../types/superUser';
import { toFilterString } from '../../../util/string.util';

const whitelistedKeys = new Set(['cm', 'dm', 'g', 'kg', 'lb', 'lpn', 'm', 'mm', 'originalEquipmentManufacturer', 'oz']);

export default function TranslationsScreen() {
  const { translations, setTranslations } = useContext(TranslationContext);

  const { superUser } = useContext(SuperUserContext);
  const [allowedLanguages, setAllowedLanguages] = useState<TranslationLanguage[]>([]);

  const { loading, refetch } = useQuery<GetTranslationsResponse, GetTranslationsVariables>(TranslationQueries.getAll, {
    onCompleted: async response => {
      for (const t of response.translations) {
        translations.set(t.language, new Translation(t.language, t.translations));
        if (superUser?.role === SuperUserRole.translator && !new Set(superUser.languages).has(t.language)) {
          continue;
        }
        allowedLanguages.push(t.language);
      }

      setAllowedLanguages([...allowedLanguages]);
      setTranslations(new Map(translations));
    },
  });

  const [selectedLanguage, setSelectedLanguage] = useState<TranslationLanguage | undefined>(undefined);
  const selectedTranslation = useMemo(() => {
    if (allowedLanguages.length === 1) setSelectedLanguage(allowedLanguages[0]);
    return translations.get(selectedLanguage || '');
  }, [translations, selectedLanguage, allowedLanguages]);

  const [items, setItems] = useState<TranslationKey[]>(TranslationUtils.allKeys);

  const [untranslatedOnly, setUntranslatedOnly] = useState<boolean>(false);
  const allItems = useMemo(() => {
    if (!untranslatedOnly) return TranslationUtils.allKeys;
    return TranslationUtils.allKeys.filter(key => {
      if (whitelistedKeys.has(key)) return false;
      return (
        toFilterString(selectedTranslation?.translations[key].singular.label) ===
          toFilterString('Undefined Translation') ||
        (selectedLanguage !== TranslationLanguage.en &&
          selectedTranslation?.translations[key].singular.label ===
            translations.get(TranslationLanguage.en)?.translations[key].singular.label)
      );
    });
  }, [untranslatedOnly, selectedTranslation]);

  const [translationRecordModalProps, setTranslationRecordModalProps] = useState<{
    open: boolean;
    record?: TranslationRecord;
    key?: TranslationKey;
  }>({ open: false });

  const translationFilter = new TranslationFilter(english.translations, selectedTranslation?.translations);

  const headers: TableHeader<TranslationKey>[] = [
    { key: 'key', name: 'Key', text: (item: TranslationKey) => item },
    {
      key: 'language',
      name: t().language.singular.upper,
      text: (item: TranslationKey) => selectedTranslation?.translations[item]?.singular?.upper || '',
    },
    {
      key: 'english',
      name: 'English',
      text: (item: TranslationKey) => english.translations[item]?.singular?.upper || '',
    },
  ];

  if (loading) {
    return (
      <Paper>
        <div className='h-full w-full flex items-center justify-center'>
          <LoadingPackage />
        </div>
      </Paper>
    );
  }

  return (
    <>
      {translationRecordModalProps.record && translationRecordModalProps.key && selectedTranslation ? (
        <TranslationRecordModal
          open={translationRecordModalProps.open}
          translationRecord={translationRecordModalProps.record}
          setOpen={open => setTranslationRecordModalProps({ ...translationRecordModalProps, open })}
          translation={selectedTranslation}
          translationKey={translationRecordModalProps.key}
        />
      ) : null}

      <FlexPane
        header={
          <SearchBarWithFilter<TranslationKey>
            items={allItems}
            setItems={setItems}
            filter={translationFilter}
            additional={[
              <>
                <div className='mr-4 my-auto'>
                  <Checkbox
                    label={t().untranslatedOnly.singular.label}
                    value={untranslatedOnly}
                    onChange={v => setUntranslatedOnly(v)}
                  />
                </div>
                <div className='w-[200px]' key='TranslationLanguage'>
                  <DropdownSelect
                    toText={item => (item === 'lt' ? 'Lithuanian' : 'English')}
                    values={new Set(allowedLanguages)}
                    selected={selectedLanguage}
                    onSelect={item => setSelectedLanguage(item as TranslationLanguage)}
                  />
                </div>
              </>,
            ]}
          />
        }
        content={
          selectedTranslation ? (
            <Table
              headers={headers}
              items={items}
              totalItemCount={selectedTranslation ? Object.keys(selectedTranslation.translations).length : 0}
              onClick={item => {
                setTranslationRecordModalProps({
                  open: true,
                  record: selectedTranslation.translations[item as TranslationKey].toRecord(),
                  key: item as TranslationKey,
                });
              }}
            />
          ) : null
        }
      />
    </>
  );
}
