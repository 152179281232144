import { gql } from '@apollo/client';

const syncMaterialMaster = gql`
  query _SAP_MaterialMasterSync($companyId: String!) {
    _SAP_MaterialMasterSync(companyId: $companyId)
  }
`;

export interface SAPSyncMaterialMasterVariables {
  companyId: string;
}

export interface SAPSyncMaterialMasterResponse {
  _SAP_MaterialMasterSync: boolean;
}

const syncStorageLocation = gql`
  query _SAP_StorageLocationSync($companyId: String!) {
    _SAP_StorageLocationSync(companyId: $companyId)
  }
`;

export interface SAPSyncStorageLocationVariables {
  companyId: string;
}

export interface SAPSyncStorageLocationResponse {
  _SAP_StorageLocationSync: boolean;
}

export const SAPQueries = {
  syncMaterialMaster,
  syncStorageLocation,
};
