import React from 'react';
import { ProductMasterData } from '../../../../types/productMasterData';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { FilterProps } from '../filter.util';
import { ProductIcon } from '../../../icons/Product/ProductIcon';
import ProductMasterDataFilterDropdownContent from '../DynamicEntityFilter/Content/ProductMasterDataFilterContent';
import ProductMasterDataFilterInnerContent from '../DynamicEntityFilter/InnerContent/ProductMasterDataFilterInnerContent';

export class ProductMasterDataFilter extends BaseFilter<ProductMasterData> {
  toLabel(): string {
    return t().productMasterData.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.product?.size || 0;
  }

  toIcon(): JSX.Element {
    return <ProductIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <ProductMasterDataFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <ProductMasterDataFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  search(item: ProductMasterData, textFilter: string) {
    if (
      toFilterString(item.productName).includes(textFilter) ||
      toFilterString(item.productNumber).includes(textFilter) ||
      toFilterString(item.globalTradeItemNumber).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }

  filteredItems(items: ProductMasterData[], query: string, filterProps: FilterProps) {
    const result = items.filter(item => {
      if (filterProps.serialManaged && !item.serialManaged) return false;
      if (filterProps.lpnManaged && !item.lpnManaged) return false;
      if (filterProps.lotManaged && !item.lotManaged) return false;

      return this.search(item, query);
    });

    return result;
  }
}
