import React from 'react';
import { Bin } from '../../../../types/bin';
import { toFilterString } from '../../../../util/string.util';
import { BinStatus } from '../../../../types/binStatus';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { BinIcon } from '../../../icons/Bin/BinIcon';
import { FilterProps } from '../filter.util';
import BinFilterDropdownContent from '../DynamicEntityFilter/Content/BinFilterContent';
import BinFilterInnerContent from '../DynamicEntityFilter/InnerContent/BinFilterInnerContent';

export class BinFilter extends BaseFilter<Bin> {
  binStatuses: Map<string, BinStatus>;

  constructor(binStatuses: Map<string, BinStatus>) {
    super();
    this.binStatuses = binStatuses;
  }

  toLabel(): string {
    return t().bin.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.bin?.size || 0;
  }

  toIcon(): React.JSX.Element {
    return <BinIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <BinFilterDropdownContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <BinFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  search(item: Bin, textFilter: string) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      toFilterString(this.binStatuses.get(item.binStatusId)?.status).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }

  filteredItems(items: Bin[], query: string, filterProps: FilterProps) {
    return items.filter(item => {
      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      return this.search(item, query);
    });
  }
}
