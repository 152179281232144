import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { IntegrationContext } from '../../../../../../context/IntegrationContext';
import { CompanyRelationService, IntegrationSettings } from '../../../../../../types/integrationSettings';
import Pane from '../../../../../../VentoryUI/components/common/Pane/Pane';
import IntegrationSAPSettingsInfo from './Common/IntegrationSAPSettingsInfo';
import UpdateIntegrationBusinessCentralSettingsPane from './UpdateIntegrationBusinessCentralSettingsPane';

interface UpdateIntegrationSettingsPaneProps {}

export default function UpdateIntegrationSettingsTypePane({}: UpdateIntegrationSettingsPaneProps) {
  const navigate = useNavigate();

  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const { currentCompany } = useContext(CompanyContext);
  const { integrationSettings, integrationSettingsLoading } = useContext(IntegrationContext);

  const [error, setError] = useState<string>('');

  if (integrationSettingsLoading) {
    return <></>;
  }

  return (
    <Pane error={error}>
      {integrationSettings.get(id)?.type === CompanyRelationService.sap ? (
        <IntegrationSAPSettingsInfo
          settingsInput={integrationSettings.get(id) || new IntegrationSettings({ companyId: currentCompany.id })}
          setSettingsInput={() => {}}
          disabled={true}
          setError={setError}
        />
      ) : (
        <UpdateIntegrationBusinessCentralSettingsPane id={id} setError={setError} />
      )}
    </Pane>
  );
}
