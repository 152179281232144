import React from 'react';
import { ShippingLocation } from '../../../../types/contact';
import { toFilterString } from '../../../../util/string.util';
import { AddressFilter } from './AddressFilter';
import { BaseFilter } from './BaseFilter';

export class ShippingLocationFilter extends BaseFilter<ShippingLocation> {
  toLabel(): string {
    return 'Not Implemented';
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: ShippingLocation, textFilter: string) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      (item.address && new AddressFilter().search(item.address, textFilter)) ||
      toFilterString(item.email).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }
}
