import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { TaskStatus, taskStatusToString } from '../../../../../types/task';
import ScrollableFilterContent from './ScrollableFilterContent';
import { TaskStatusFilter } from '../../Filter/TaskStatusFilter';

interface TaskStatusFilterDropdownContentProps {
  filter: TaskStatusFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function TaskStatusFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: TaskStatusFilterDropdownContentProps) {
  const { stockLocations } = useContext(StockLocationContext);

  const [query, setQuery] = useState<string>('');

  const filterTaskStatus = () => {
    return new Set(filter.filteredItems([...Object.keys(TaskStatus), 'Overdue'], query, filterProps).map(item => item));
  };

  const [values, setValues] = useState<Set<string>>(filterTaskStatus());

  const handleChange = (selected: Set<string>) => {
    filterProps.taskStatus = selected;
    if (!filterProps.taskStatus?.size) filterProps.product = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.taskStatus = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterTaskStatus());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().taskStatus.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.taskStatus}
            values={values}
            onChange={handleChange}
            toText={id => taskStatusToString(id, false)}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
