import React from 'react';
import { ReorderUserRuleGroup } from '../../../../components/Operation/Order/Settings/Panes/OrderReorderUsersPane';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from './BaseFilter';
import { t } from '../../../../types/translation/Translator';

export class ReorderUserRuleFilter extends BaseFilter<ReorderUserRuleGroup> {
  toLabel(): string {
    return t().reorderUserRule.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: ReorderUserRuleGroup, textFilter: string) {
    if (toFilterString(item.rules[0].name).includes(textFilter)) {
      return true;
    }

    return false;
  }
}
