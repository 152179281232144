import React from 'react';
import InnerFilterContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';
import { Country } from '../../../../../types/common/address';

export default function CountryFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<Country>) {
  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.country || [])]}
      onRemove={i => {
        filterProps.country?.delete(i);
        if (!filterProps.country?.size) filterProps.country = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
