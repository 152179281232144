import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import { ProductMasterDataExportConfiguration } from '../../../../../../types/productMasterDataExportConfiguration';
import { t } from '../../../../../../types/translation/Translator';

interface ProductMasterDataExportConfigurationItemProps {
  configuration?: ProductMasterDataExportConfiguration;
  onClick?: () => void;
  ids?: Set<string>;
}

export default function ProductMasterDataExportConfigurationItem({
  configuration,
  onClick,
  ids = new Set(),
}: ProductMasterDataExportConfigurationItemProps) {
  const navigate = useNavigate();

  return (
    <Grid item className='w-40 h-40 p-1 inline-flex'>
      <Grid
        container
        onClick={onClick ? onClick : () => navigate(configuration ? `${configuration.id}/update` : 'create')}
        alignContent={'center'}
        className={`w-full h-full cursor-pointer rounded-lg ${
          ids.has(configuration?.id || '') ? 'border-blue-500 border-2' : 'border'
        }`}
      >
        <Grid item xs={12} textAlign={'center'}>
          {configuration ? (
            <span style={{ height: '75px' }} className='fiv-viv fiv-icon-conf fiv-size-lg file_icon'></span>
          ) : (
            <AddIcon style={{ height: '75px' }} />
          )}
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <p className='text-sm select-none'>{configuration?.name || t().newText.singular.label}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}
