import React, { useContext } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import Color from 'color';
import { Grid } from '@mui/material';
import OverviewMetrics from '../../VentoryUI/components/common/Dashboard/OverviewMetrics';
import OrderMetrics from '../../VentoryUI/components/common/Dashboard/OrderMetrics';
import TaskMetrics from '../../VentoryUI/components/common/Dashboard/TaskMetrics';
import Pane from '../../VentoryUI/components/common/Pane/Pane';
import { Colors } from '../../VentoryUI/util/color.util';

interface DashboardScreenInputProps {}

export default function DashboardScreen({}: DashboardScreenInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const color = Color(Colors.getPrimaryColor(currentCompany)).toString();

  const hasTask = !!currentCompany.settings.featureToggles.tasks.tasks;
  const hasOrders = !!currentCompany.settings.featureToggles.orders.orders;

  return (
    <Pane overflow='auto'>
      <Grid container className='h-full' display='flex' alignContent={'space-between'}>
        <Grid item xs={12}>
          <OverviewMetrics color={color} />
        </Grid>
        <Grid item xs={12}>
          {hasTask ? <TaskMetrics color={color} /> : null}
        </Grid>
        <Grid item xs={12}>
          {hasOrders ? <OrderMetrics color={color} /> : null}
        </Grid>
      </Grid>
    </Pane>
  );
}
