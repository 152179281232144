import React from 'react';
import { JSX } from 'react';
import { BinStatus } from '../../../../types/binStatus';
import { t } from '../../../../types/translation/Translator';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from './BaseFilter';

export class BinStatusFilter extends BaseFilter<BinStatus> {
  toLabel(): string {
    return t().binStatus.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: BinStatus, textFilter: string) {
    if (toFilterString(item.status).includes(textFilter)) {
      return true;
    }

    return false;
  }
}
