import { Grid } from '@mui/material';
import React, { useContext, useMemo, useRef, useState } from 'react';
import Color from 'color';
import { StyleType, ElementHeight, Size } from '../Style';
import Badge, { BadgeProps } from '../Badge/Badge';
import { CompanyContext } from '../../../../context/CompanyContext';
import { handleKeyEvent } from '../../../../util/events.util';
import { Tooltip as MuiTooltip } from '@mui/material';
import { classes, TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { LoadingSpinner } from '../../../../components/Common/LoadingSpinner';
import { Colors } from '../../../util/color.util';

export interface FooterButton extends ButtonProps {
  align?: 'left' | 'right';
}

function buttonSizeToHeight(size: Size): ElementHeight {
  switch (size) {
    case 'md':
      return '34px';
  }
}

interface ButtonColorProps {
  color?: string;
  backgroundColor?: string;
  textColor?: string;
  border?: boolean;
}

export interface ButtonTemplateProps {
  testId?: TestIdIdentifier;
  loading?: boolean;
  disabled?: boolean;
  style?: StyleType;
  text?: string;
  size?: Size;
  tooltip?: React.ReactNode;
  badge?: number | BadgeProps;
  startIcon?: JSX.Element;
  id?: string;
}

export interface ButtonProps extends ButtonTemplateProps {
  text?: string;
  onClick: () => void;
  color?: ButtonColorProps;
  endIcon?: JSX.Element;
}

export function Button({
  text,
  testId,
  color,
  style = 'primary',
  startIcon,
  endIcon,
  size = 'md',
  onClick = () => {},
  badge,
  loading = false,
  disabled = false,
  tooltip,
  id,
}: ButtonProps) {
  const { currentCompany } = useContext(CompanyContext);
  const ref = useRef<HTMLDivElement | null>(null);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const { textColor, backgroundColor, border } = useMemo(() => {
    let backgroundColor;
    let textColor;
    let border;

    if (!color) {
      backgroundColor = Colors.getBackgroundColor(currentCompany, style);
      textColor = Colors.getTextColor(currentCompany, style);
      if (
        style === 'primary' ||
        (style === 'secondary' && new Color(Colors.getPrimaryColor(currentCompany)).lightness() > 90)
      ) {
        border = 'border border-ventory-light-border';
      }
    } else {
      backgroundColor = color.color ? Colors.lightBackground(color.color) : color.backgroundColor;

      textColor = color.color ? Colors.darkText(color.color) : color.textColor;
      border = color.border ? 'border border-ventory-light-border' : '';
    }

    return { backgroundColor, textColor, border };
  }, [color, style]);

  const handleInteraction = () => {
    if (loading || disabled) return;
    onClick();
  };

  // TODO: Create own tooltip
  return (
    <MuiTooltip
      open={tooltip ? tooltipOpen : false}
      onClose={() => setTooltipOpen(false)}
      onOpen={() => setTooltipOpen(true)}
      title={tooltip}
    >
      <Grid
        id={id}
        item
        className={`${classes.button.name} select-none relative ${
          disabled || loading ? 'opacity-70' : ''
        } text-ventory-grey-600`}
        style={{ height: buttonSizeToHeight(size) }}
        onClick={handleInteraction}
        onKeyDown={event => handleKeyEvent(event, 'Enter', handleInteraction)}
        data-testid={testId?.name}
        ref={ref}
      >
        {ref ? typeof badge === 'number' ? <Badge content={badge} /> : <Badge {...badge} /> : null}

        <Grid
          bgcolor={backgroundColor}
          color={textColor}
          container
          justifyContent={'center'}
          className={`px-4 rounded-4 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} w-fit h-full ${border}`}
        >
          {loading ? (
            <Grid item alignContent={'center'}>
              <LoadingSpinner style={style} />
            </Grid>
          ) : (
            <>
              {startIcon ? (
                <Grid item alignContent={'center'} className={text ? 'pr-2' : ''} style={{ color: textColor }}>
                  {startIcon}
                </Grid>
              ) : null}
              {text ? (
                <Grid item alignContent={'center'} alignItems={'center'}>
                  <p className={`text-[14px] font-normal`} style={{ color: textColor }}>
                    {text}
                  </p>
                </Grid>
              ) : null}
              {endIcon ? (
                <Grid item alignContent={'center'} className={text ? 'pl-2' : ''} style={{ color: textColor }}>
                  {endIcon}
                </Grid>
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
    </MuiTooltip>
  );
}
