import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { TaskType, taskTypeToString } from '../../../../../types/task';
import ScrollableFilterContent from './ScrollableFilterContent';
import { TaskTypeFilter } from '../../Filter/TaskTypeFilter';

interface TaskTypeFilterDropdownContentProps {
  filter: TaskTypeFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function TaskTypeFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: TaskTypeFilterDropdownContentProps) {
  const { stockLocations } = useContext(StockLocationContext);

  const [query, setQuery] = useState<string>('');

  const filterTaskType = () => {
    return new Set(filter.filteredItems([...Object.keys(TaskType)], query, filterProps).map(item => item));
  };

  const [values, setValues] = useState<Set<string>>(filterTaskType());

  const handleChange = (selected: Set<string>) => {
    filterProps.taskType = selected;
    if (!filterProps.taskType?.size) filterProps.product = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.taskType = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterTaskType());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().taskType.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.taskType}
            values={values}
            onChange={handleChange}
            toText={id => taskTypeToString(id as TaskType)}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
