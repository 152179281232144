import React from 'react';
import { OrderStatus, orderStatusToLocalizedString } from '../../../../../types/order';
import InnerFilterContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function OrderStatusFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<string>) {
  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.orderStatus || [])]}
      text={item => orderStatusToLocalizedString(item as OrderStatus)}
      onRemove={i => {
        filterProps.orderStatus?.delete(i);
        if (!filterProps.orderStatus?.size) filterProps.orderStatus = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
