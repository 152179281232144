import React from 'react';
import { User } from '../../../../types/user';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { UserAddIcon } from '../../../icons/User/UserAddIcon';
import UserFilterInnerContent from '../DynamicEntityFilter/InnerContent/UserFilterInnerContent';
import UserFilterDropdownContent from '../DynamicEntityFilter/Content/UserFilterContent';
import { toFilterString } from '../../../../util/string.util';

export class UserFilter extends BaseFilter<User> {
  users: Map<string, User>;

  constructor(users: Map<string, User>) {
    super();
    this.users = users;
  }

  toLabel(props: BaseFilterProps): string {
    return t().user.plural.label;
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <UserFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <UserFilterDropdownContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toIcon(props: BaseFilterProps): React.JSX.Element {
    return <UserAddIcon className={this.className} />;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.user?.size || 0;
  }

  search(item: User, textFilter: string) {
    const user = this.users.get(item.userId);

    if (
      toFilterString(item.email).includes(textFilter) ||
      toFilterString(`${user?.firstName} ${user?.lastName}`).includes(textFilter)
    ) {
      return true;
    }
    return false;
  }
}
