import React from 'react';
import { Contact } from '../../../../types/contact';
import { t } from '../../../../types/translation/Translator';
import { toFilterString } from '../../../../util/string.util';
import { AddressFilter } from './AddressFilter';
import { BaseFilter } from './BaseFilter';

export class ContactFilter extends BaseFilter<Contact> {
  toLabel(): string {
    return t().contact.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: Contact, textFilter: string) {
    const addressFilter = new AddressFilter();
    if (
      toFilterString(item.name).includes(textFilter) ||
      (item.address && addressFilter.search(item.address, textFilter)) ||
      toFilterString(item.email).includes(textFilter) ||
      item.shippingLocations.some(
        sl =>
          toFilterString(sl.name).includes(textFilter) ||
          toFilterString(sl.email).includes(textFilter) ||
          (sl.address && addressFilter.search(sl.address, textFilter)),
      )
    ) {
      return true;
    }
    // Moved from ContactOverviewPane handleSearch(). need to check if && or already covered by above case
    // if (removeDiacritics(toFilterString(item.name)).includes(textFilter)) {
    //   return true;
    // }

    return false;
  }
}
