import React from 'react';
import { StockLocation } from '../../../../types/stockLocation';
import { toFilterString } from '../../../../util/string.util';
import { AddressFilter } from './AddressFilter';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { LocationMarkerIcon } from '../../../icons/LocationMarker/LocationMarkerIcon';
import StockLocationFilterInnerContent from '../DynamicEntityFilter/InnerContent/StockLocationFilterInnerContent';
import StockLocationFilterDropdownContent from '../DynamicEntityFilter/Content/StockLocationFilterContent';
import { FilterProps } from '../filter.util';

export class StockLocationFilter extends BaseFilter<StockLocation> {
  toLabel(props: BaseFilterProps): string {
    return t().stockLocation.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.stockLocation?.size || 0;
  }

  toIcon(): JSX.Element {
    return <LocationMarkerIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <StockLocationFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <StockLocationFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  search(item: StockLocation, query: string) {
    if (
      toFilterString(item.name).includes(query) ||
      toFilterString(item.identifier).includes(query) ||
      (item.address && new AddressFilter().search(item.address, query))
    ) {
      return true;
    }

    return false;
  }

  filteredItems(items: StockLocation[], query: string, filterProps: FilterProps) {
    const result = items.filter(item => {
      item.address?.addressLine1;

      return this.search(item, query);
    });

    return result;
  }
}
