import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { TranslationContext } from '../../../../context/TranslationContext';
import {
  TranslationMutations,
  UpdateTranslationRecordResponse,
  UpdateTranslationRecordVariables,
} from '../../../../graphql/translations/translation.graphql';
import { TranslationRecord, TranslationUtils } from '../../../../types/translation/common';
import { Translation, TranslationLanguage } from '../../../../types/translation/translation';
import { TranslationKey } from '../../../../types/translation/TranslationKey';
import { translationsManager } from '../../../../types/translation/Translator';
import { SaveButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import TextInput from '../../../Common/TextInput';

interface TranslationRecordModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  translation: Translation;
  translationRecord: TranslationRecord;
  translationKey: TranslationKey;
}

export default function TranslationRecordModal({
  open,
  setOpen,
  translation,
  translationRecord,
  translationKey,
}: TranslationRecordModalProps) {
  const [record, setRecord] = useState(translationRecord);

  // For Developer User (must turn off Strict Mode so it doesn't double update)
  // useEffect(() => {
  //   document.addEventListener(
  //     'keydown',
  //     event =>
  //       handleKeyEvent(event, 'Enter', async event => {
  //         if (!open) return;
  //         await update();
  //       }),
  //     false,
  //   );
  // }, []);

  const { translations, setTranslations } = useContext(TranslationContext);
  const [error, setError] = useState('');

  const englishRecord = translations.get(TranslationLanguage.en)?.translations[translationKey]?.toRecord();

  const handleClose = () => {
    setError('');
    setRecord({ singular: { lower: '' } });
    setOpen(false);
  };

  useEffect(() => {
    setRecord(translationRecord);
  }, [translationRecord]);

  const [update, { loading }] = useMutation<UpdateTranslationRecordResponse, UpdateTranslationRecordVariables>(
    TranslationMutations.updateRecord,
    {
      variables: {
        key: translationKey,
        language: translation.language,
        record: record,
      },
      onCompleted: response => {
        const translation = new Translation(
          response.updateTranslationRecord.language,
          response.updateTranslationRecord.translations,
        );
        setTranslations(new Map(translations).set(response.updateTranslationRecord.language, translation));
        translationsManager.setTranslation(translation);
        handleClose();
      },
      onError: response => {
        setError(response.message);
      },
    },
  );

  if (!englishRecord) return null;

  const hasVariables =
    TranslationUtils.checkVariables(englishRecord.singular.lower, record.singular.lower) &&
    TranslationUtils.checkVariables(englishRecord.singular.upper || '', record.singular.upper || '') &&
    TranslationUtils.checkVariables(englishRecord.plural?.lower || '', record.plural?.lower || '') &&
    TranslationUtils.checkVariables(englishRecord.plural?.upper || '', record.plural?.upper || '');

  const hasRequired =
    !(englishRecord.singular.lower && !record.singular.lower) &&
    !(englishRecord.singular.upper && !record.singular.upper) &&
    !(englishRecord.plural?.lower && !record.plural?.lower) &&
    !(englishRecord.plural?.upper && !record.plural?.upper);

  const canSave = hasVariables && hasRequired;

  return (
    <Modal title={'Translation Record'} onClose={handleClose} open={open} error={error}>
      <ModalPane
        footerButtons={[
          SaveButtonTemplate(update, {
            loading,
            disabled: !canSave,
            tooltip: !hasVariables ? 'Variables like this: "${VALUE}" must be present in your translation.' : null,
          }),
        ]}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().singular.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput label={'Key'} disabled value={translationKey} onChange={() => null} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().singular.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().lowercase.singular.label}
                  disabled
                  value={englishRecord.singular.lower}
                  onChange={() => null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().uppercase.singular.upper}
                  disabled
                  value={englishRecord.singular.upper}
                  onChange={() => null}
                />
              </Grid>
              <Grid item xs={12} my={1}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().plural.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().lowercase.singular.label}
                  disabled
                  value={englishRecord.plural?.lower}
                  onChange={() => null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().uppercase.singular.label}
                  disabled
                  value={englishRecord.plural?.upper}
                  onChange={() => null}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().singular.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  autoFocus
                  dynamicUpdate
                  label={t().lowercase.singular.label}
                  value={record.singular.lower}
                  onChange={value => {
                    record.singular.lower = value;
                    setRecord({ ...record });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  dynamicUpdate
                  label={t().uppercase.singular.upper}
                  value={record.singular.upper}
                  onChange={value => {
                    record.singular.upper = value;
                    setRecord({ ...record });
                  }}
                  disabled={!englishRecord.singular.upper}
                />
              </Grid>
              <Grid item xs={12} my={1}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().plural.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  dynamicUpdate
                  label={t().lowercase.singular.label}
                  value={record.plural?.lower}
                  disabled={!englishRecord.plural?.lower && translation.language !== TranslationLanguage.en}
                  onChange={value => {
                    if (!record.plural) record.plural = { lower: value };
                    else record.plural.lower = value;
                    setRecord({ ...record });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  dynamicUpdate
                  label={t().uppercase.singular.label}
                  value={record.plural?.upper}
                  onChange={value => {
                    if (record.plural) record.plural.upper = value;
                    setRecord({ ...record });
                  }}
                  disabled={!englishRecord.plural?.upper}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
