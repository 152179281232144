import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { ReorderRuleContext } from '../../../context/ReorderRuleContext';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import { ProductMasterData } from '../../../types/productMasterData';
import { ReorderRule } from '../../../types/reorderRule';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { StockLocationAggregateType } from '../../../types/common/stockLocationAggregateType';
import { CompanyContext } from '../../../context/CompanyContext';
import { StockLocationContext } from '../../../context/StockLocationContext';
import DeleteReorderRuleModal from './modals/DeleteReorderRuleModal';
import { StockLocation } from '../../../types/stockLocation';
import { useNavigate } from 'react-router-dom';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { ReorderRuleFilter } from '../../../VentoryUI/components/filters/Filter/ReorderRuleFilter';
import { ButtonProps } from '../../../VentoryUI/components/common/Button/Button';
import { AddButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/AddButton';
import { DeleteButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table, { TableHeader } from '../../../VentoryUI/components/common/Table/Table';

interface ReorderRulesOverviewPaneProps {
  productMasterDataId?: string;
}

export interface ReorderRuleGroup {
  groupId: string;
  rules: ReorderRule[];
}

const getHeaders = (
  productMasterData: Map<string, ProductMasterData>,
  stockLocations: Map<string, StockLocation>,
): TableHeader<ReorderRuleGroup>[] => [
  {
    key: 'name',
    name: t().name.singular.label,
    text: (item: ReorderRuleGroup) => {
      return item.rules[0].name;
    },
  },
  {
    key: 'products',
    name: t().product.plural.label,
    text: (item: ReorderRuleGroup) => {
      if (!item?.rules[0]?.productMasterDataId) return 'Any product';
      return new Set(item.rules.map(rule => rule.productMasterDataId)).size.toString();
    },
  },
  {
    key: 'stockLocation',
    name: t().location.singular.label,
    text: (item: ReorderRuleGroup) => {
      const rule = item?.rules[0];
      const uniqueStockLocations = new Set(item.rules.map(rule => rule.stockLocationId));

      switch (rule?.stockLocationAggregateType) {
        case StockLocationAggregateType.all:
          return 'Across all locations';
        case StockLocationAggregateType.allOf:
          if (uniqueStockLocations.size > 1) {
            return 'Across ' + uniqueStockLocations.size + ' locations';
          } else {
            return `Across ${[...uniqueStockLocations]
              .map(id => stockLocations.get(id || '')?.name || 'Unknown Stock Location')
              .join(' and ')}`;
          }
        case StockLocationAggregateType.any:
          return 'In any location';
        case StockLocationAggregateType.anyOf:
          if (uniqueStockLocations.size > 1) {
            return 'In any of ' + uniqueStockLocations.size + ' locations';
          } else {
            return `In ${[...uniqueStockLocations]
              .map(id => stockLocations.get(id || '')?.name || 'Unknown Stock Location')
              .join(' or ')}`;
          }
      }
    },
  },
];

export default function ReorderRulesOverviewPane({ productMasterDataId }: ReorderRulesOverviewPaneProps) {
  const reorderRuleFilter = new ReorderRuleFilter();
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { reorderRulesLoading, groupedReorderRules } = useContext(ReorderRuleContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { stockLocations } = useContext(StockLocationContext);

  const [deletedModalOpen, setDeleteModalOpen] = useState(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const filteredRules = useMemo(() => {
    const groups: ReorderRuleGroup[] = [];

    for (const [groupId, rules] of groupedReorderRules) {
      if (productMasterDataId && !rules.some(rule => rule.productMasterDataId === productMasterDataId)) continue;

      if (hasCompanyRole(currentCompany.id, CompanyRole.administrator)) {
        groups.push({ groupId, rules });
        continue;
      } else {
        if (
          rules.some(rule => {
            if (!rule.stockLocationId) return false;
            else
              return !hasStockLocationRole(
                rule.companyId,
                rule.stockLocationId,
                StockLocationRole.STOCK_LOCATION_MANAGER,
              );
          })
        ) {
          continue;
        }
      }

      groups.push({ groupId, rules });
    }

    return groups;
  }, [groupedReorderRules]);

  const [items, setItems] = useState(filteredRules);

  // function handleFilter(item: ReorderRuleGroup) {
  //   if (!filter) return true;
  //   if (!item.rules.length) return false;
  //   const rule = item?.rules[0];
  //   const pmd = productMasterData.get(rule.productMasterDataId || '');
  //   if (pmd) {
  //     if (pmd.productName.includes(filter)) return true;
  //     if (pmd.productNumber.includes(filter)) return true;
  //   }
  //   if (rule.stockLocationAggregateType.includes(filter)) return true;
  //   const stockLocationIds = item.rules
  //     .filter(rule => rule.stockLocationId)
  //     .map(rule => rule.stockLocationId) as string[];
  //   if (
  //     stockLocationIds.some(id => {
  //       return (
  //         removeDiacritics(toFilterString(stockLocations.get(id)?.name)).includes(filter) ||
  //         removeDiacritics(toFilterString(stockLocations.get(id)?.identifier)).includes(filter)
  //       );
  //     })
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }

  const buttons = useMemo(() => {
    const shown: ButtonProps[] = [];

    if (selected.size) {
      shown.push(DeleteButtonTemplate(() => setDeleteModalOpen(true)));
    }

    shown.push(AddButtonTemplate(() => navigate('/reference_data/stock_triggers/create/general')));

    return shown;
  }, [selected]);

  return (
    <>
      <DeleteReorderRuleModal open={deletedModalOpen} setOpen={setDeleteModalOpen} groupIds={[...selected]} />

      <FlexPane
        header={
          <SearchBarWithFilter
            items={filteredRules}
            setItems={setItems}
            placeholder={t().filterReorderRules.singular.label}
            buttons={buttons}
            filter={reorderRuleFilter}
          />
        }
        content={
          <Table<ReorderRuleGroup>
            loading={reorderRulesLoading || productMasterDataLoading}
            items={items}
            headers={getHeaders(productMasterData, stockLocations)}
            onClick={item => navigate(`/reference_data/stock_triggers/${item.groupId}/update/general`)}
            onSelected={items => setSelected(new Set(items))}
            uniqueIdentifier='groupId'
          />
        }
      />
    </>
  );
}
