import React from 'react';
import { FilterProps } from '../filter.util';
import { DropdownButton } from '../../common/DropdownButton/DropdownButton';

export interface BaseFilterProps {
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export abstract class BaseFilter<T> {
  filterOptions(): BaseFilter<any>[] {
    return [];
  }

  className?: string;
  color?: string;

  abstract toLabel(props: BaseFilterProps): string;
  abstract toInnerContent(props: BaseFilterProps): string | JSX.Element;
  abstract toDropdownContent(props: BaseFilterProps): React.JSX.Element;
  abstract toIcon(props: BaseFilterProps): React.JSX.Element;
  abstract toCount(props: BaseFilterProps): number;

  toString(props: BaseFilterProps): string {
    return this.toLabel(props);
  }

  filteredItems(items: T[], query: string, filterProps: FilterProps): T[] {
    return items.filter(item => this.search(item, query));
  }

  search(item: T, query: string): boolean {
    return true;
  }

  toComponent(props: BaseFilterProps) {
    return (
      <>
        <p className='mb-1'>{this.toLabel(props)}</p>
        <DropdownButton
          width='100%'
          content={this.toInnerContent(props)}
          dropdownContent={this.toDropdownContent(props)}
          startIcon={this.toIcon(props)}
          badge={this.toCount(props)}
        />
      </>
    );
  }
}
