import { Divider, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { Order, OrderStatus, OrderType, orderTypeToLocalizedString } from '../../../../types/order';
import CustomFieldOrderInput from '../../../Common/CustomFieldOrderInput';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';
import { ContactContext } from '../../../../context/ContactContext';
import { CustomFieldEntitySubType, CustomFieldEntityType } from '../../../../types/customField';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';
import { t } from '../../../../types/translation/Translator';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import { CompanyContext } from '../../../../context/CompanyContext';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { UserContext } from '../../../../context/UserContext';

interface OrderInfoPaneProps {
  order?: Order;
  setOrder: (order: Order) => void;
  footer: (canUpdate?: boolean) => JSX.Element;
}

export default function OrderInfoPane({ order, setOrder, footer }: OrderInfoPaneProps) {
  if (!order) return null;

  const { currentCompany } = useContext(CompanyContext);
  const { filteredStockLocations, stockLocations } = useContext(StockLocationContext);
  const { contacts } = useContext(ContactContext);
  const { customFields } = useContext(CustomFieldContext);
  const { companyUsers } = useContext(UserContext);

  const entitySubtype =
    order.type === OrderType.inbound ? CustomFieldEntitySubType.inbound : CustomFieldEntitySubType.outbound;

  const customFieldItems = [...customFields.values()].filter(
    v => v.entityType === CustomFieldEntityType.order && (!v.entitySubtype || v.entitySubtype === entitySubtype),
  );

  const fieldsDisabled =
    order.status === OrderStatus.complete ||
    order.status === OrderStatus.cancelled ||
    order.status === OrderStatus.failed ||
    order.status === OrderStatus.disabled;

  const disabled =
    fieldsDisabled ||
    !!customFieldItems
      .filter(cf => cf.mandatory)
      .filter(cf => {
        if (!order.customFields.has(cf.id)) return true;
        if (!order.customFields.get(cf.id)!.value) return true;
        return false;
      }).length;

  return (
    <FlexPane
      testId={testIds.orderInfoPane}
      content={
        <Paper>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextInput
                disabled
                dynamicUpdate
                onChange={() => {}}
                label={t().order.singular.label}
                value={order.number || '0'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                disabled
                dynamicUpdate
                onChange={() => {}}
                label={t().orderType.singular.label}
                testId={testIds.type}
                value={orderTypeToLocalizedString(order.type)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  dynamicUpdate
                  onChange={() => {}}
                  label={
                    order.type === OrderType.inbound
                      ? t().destinationStockLocation.singular.label
                      : t().sourceStockLocation.singular.label
                  }
                  testId={testIds.stockLocation}
                  value={filteredStockLocations.get(order.stockLocationId)?.name || ''}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                disabled={true}
                value={`${new Date(order.createdAt).toLocaleDateString()} - ${new Date(
                  order.createdAt,
                ).toLocaleTimeString()}`}
                label={t().createdAt.singular.label}
                onChange={v => {}}
                placeholder={t().createdAt.singular.label}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                disabled={true}
                value={`${companyUsers.get(order.createdBy)?.email || 'Unknown user'}`}
                label={t().createdBy.singular.label}
                onChange={v => {}}
                placeholder={t().createdBy.singular.label}
              />
            </Grid>
            {!currentCompany.settings.featureToggles.orders.supplier &&
            !currentCompany.settings.featureToggles.orders.customer ? null : (
              <>
                <Grid item xs={6}>
                  <DropdownSelect
                    maxHeight='200px'
                    disabled={disabled}
                    label={order.type === OrderType.inbound ? t().supplier.singular.label : t().customer.singular.label}
                    values={[...contacts.values()]}
                    selectedValue={contacts.get(order.contactId || '') || null}
                    toText={item => item.name}
                    onChange={v => setOrder(order.withContactId(v?.id))}
                    testId={testIds.contact}
                    placeholder={
                      order.type === OrderType.inbound ? t().supplier.singular.label : t().customer.singular.label
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropdownSelect
                    maxHeight='200px'
                    disabled={disabled}
                    label={t().location.singular.label}
                    values={contacts.get(order.contactId || '')?.shippingLocations || []}
                    selectedValue={order.contactLocation || null}
                    toText={item => item.name}
                    onChange={v => setOrder(order.withContactLocation(v))}
                    testId={testIds.contactLocation}
                    placeholder={t().location.singular.label}
                  />
                </Grid>
              </>
            )}
            {order.externalReferenceId || order.purchaseOrderNumber || order.estimatedTimeOfArrival ? (
              <Grid item xs={12} mt={1} mb={1}>
                <Divider />
              </Grid>
            ) : null}
            {order.externalReferenceId ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={disabled}
                  value={order.externalReferenceId}
                  label={t().externalReferenceId.singular.label}
                  onChange={v => order.withExternalReferenceId(v)}
                  placeholder={t().externalReferenceId.singular.label}
                />
              </Grid>
            ) : null}
            {order.purchaseOrderNumber ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={disabled}
                  value={order.purchaseOrderNumber}
                  label={t().purchaseOrderNumber.singular.label}
                  onChange={v => order.withPurchaseOrderNumber(v)}
                  testId={testIds.purchaseOrderNumber}
                  placeholder={t().purchaseOrderNumber.singular.label}
                />
              </Grid>
            ) : null}
            {order.estimatedTimeOfArrival ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={disabled}
                  label={t().estimatedTimeOfArrival.singular.label}
                  placeholder={t().estimatedTimeOfArrival.singular.label}
                  value={new Date(order.estimatedTimeOfArrival).toLocaleDateString()}
                  onChange={v => {}}
                />
              </Grid>
            ) : null}
            {order.customFields.size ? (
              <>
                <Grid item xs={12}>
                  <CustomFieldOrderInput disabled={disabled} order={order} setOrder={setOrder} />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Paper>
      }
      footer={footer(true)}
    />
  );
}
