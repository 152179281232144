import React from 'react';
import InnerFilterContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';
import { TaskType, taskTypeToString } from '../../../../../types/task';

export default function TaskTypeFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<string>) {
  return (
    <InnerFilterContent
      filterProps={{ filterProps, setFilterProps }}
      filter={filter}
      items={[...(filterProps.taskType || [])]}
      text={item => taskTypeToString(item as TaskType)}
      onRemove={i => {
        filterProps.taskType?.delete(i);
        if (!filterProps.taskType?.size) filterProps.taskType = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
