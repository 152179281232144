import React, { JSX } from 'react';
import dayjs from 'dayjs';
import { toFilterString } from '../../../../util/string.util';
import { FileEntity } from '../../../../types/file';
import { t } from '../../../../types/translation/Translator';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import { CalendarIcon } from '../../../icons/Calendar/CalendarIcon';
import DateTimeFilterContent from '../DynamicEntityFilter/Content/DateTimeFilterContent';
import { FilterProps } from '../filter.util';

export class DateTimeFilter extends BaseFilter<FileEntity> {
  toLabel(props: BaseFilterProps): string {
    return t().dateAndTime.singular.label;
  }

  toString(props: BaseFilterProps): string {
    if (!props) return this.toLabel(props);

    return props.filterProps.timeFrame
      ? `${new Date(props.filterProps.timeFrame.start).toLocaleDateString()} - ${new Date(
          props.filterProps.timeFrame.end,
        ).toLocaleDateString()}`
      : this.toLabel(props);
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <CalendarIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <DateTimeFilterContent
        filter={props.filterProps}
        setFilter={props.setFilterProps}
        color={this.color || 'black'}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return this.toString(props);
  }

  search(item: FileEntity, textFilter: string) {
    if (toFilterString(item.name).includes(textFilter) || toFilterString(item.type).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: FileEntity[], query: string, filterProps: FilterProps) {
    return items.filter(item => {
      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filterProps.user && !filterProps.user.has(item.createdBy)) return false;
      if (filterProps.fileTypeCategory && !filterProps.fileTypeCategory.has(item.fileTypeCategory)) return false;

      return this.search(item, query);
    });
  }
}
