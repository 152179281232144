import React from 'react';
import { FilterProps } from '../filter.util';
import { BaseFilter, BaseFilterProps } from './BaseFilter';
import { Product } from '../../../../types/product';
import { t } from '../../../../types/translation/Translator';
import { ProductIcon } from '../../../icons/Product/ProductIcon';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import { ProductMasterData } from '../../../../types/productMasterData';
import { BinFilter } from './BinFilter';
import { BinStatus } from '../../../../types/binStatus';
import { StockLocationFilter } from './StockLocationFilter';

export class ProductFilter extends BaseFilter<Product> {
  productMasterData: Map<string, ProductMasterData>;
  binStatuses: Map<string, BinStatus>;

  constructor(productMasterData: Map<string, ProductMasterData>, binStatuses: Map<string, BinStatus>) {
    super();
    this.productMasterData = productMasterData;
    this.binStatuses = binStatuses;
  }

  filterOptions = () => [new StockLocationFilter(), new BinFilter(this.binStatuses)];

  toLabel = () => {
    return t().product.singular.label;
  };

  toCount(props: BaseFilterProps) {
    return props.filterProps.product?.size || 0;
  }

  toDropdownContent(props: BaseFilterProps) {
    return <></>;
  }

  toInnerContent = (props: BaseFilterProps) => {
    return <></>;
  };

  toIcon = () => {
    return <ProductIcon className={this.className} />;
  };

  search = (item: Product, filter: string) => {
    const productName = this.productMasterData.get(item.productMasterDataId)?.productName;
    const productNumber = this.productMasterData.get(item.productMasterDataId)?.productNumber;

    if (
      removeDiacritics(toFilterString(productName)).includes(removeDiacritics(filter)) ||
      removeDiacritics(toFilterString(productNumber)).includes(removeDiacritics(filter))
    ) {
      return true;
    }
    return false;
  };

  filteredItems(items: Product[], query: string, filterProps: FilterProps): Product[] {
    return items.filter(item => {
      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      if (filterProps.bin && filterProps.bin.size && !filterProps.bin.has(item.binId)) {
        return false;
      }

      return this.search(item, query);
    });
  }
}
