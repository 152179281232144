import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { t } from '../../../../../types/translation/Translator';
import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CustomTranslationContext } from '../../../../../context/CustomTranslationContext';
import {
  CreateCustomTranslationResponse,
  CreateCustomTranslationVariables,
  CustomTranslationMutations,
} from '../../../../../graphql/translations/customTranslation.graphql';
import { english } from '../../../../../types/translation/Translator';
import { CustomTranslation } from '../../../../../types/translation/customTranslation';
import CreateButton from '../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import { FlexPane } from '../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../../VentoryUI/components/common/Paper/Paper';
import SearchBarWithFilter from '../../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import Table, { TableHeader } from '../../../../../VentoryUI/components/common/Table/Table';
import LoadingPackage from '../../../../Common/LoadingPackage';
import TextInput from '../../../../Common/TextInput';
import CustomTranslationRecordModal from './Modals/CustomTranslationRecordModal';
import { TranslationKey } from '../../../../../types/translation/TranslationKey';
import { TranslationFilter } from '../../../../../VentoryUI/components/filters/Filter/TranslationFilter';
import { TranslationUtils } from '../../../../../types/translation/common';

interface CustomTranslationPaneProps {
  setError: (error: string) => void;
}

export default function CustomTranslationPane({ setError }: CustomTranslationPaneProps) {
  const { customTranslations, customTranslationsLoading } = useContext(CustomTranslationContext);

  const [items, setItems] = useState(TranslationUtils.allKeys);
  const [props, setProps] = useState<{ open: boolean; key?: TranslationKey }>({ open: false, key: undefined });

  if (customTranslationsLoading)
    return (
      <Paper>
        <div className='w-full h-full flex items-center justify-center'>
          <LoadingPackage />
        </div>
      </Paper>
    );

  if (!customTranslations.size) return <CreateCustomTranslationView setError={setError} />;
  const customTranslation = [...customTranslations.values()][0];
  if (!customTranslation) return <CreateCustomTranslationView setError={setError} />;

  const translationFilter = new TranslationFilter(english.translations, customTranslation.translations);

  const headers: TableHeader<TranslationKey>[] = [
    {
      key: 'key',
      name: 'Key',
      text: (item: TranslationKey) => item,
    },
    {
      key: 'english',
      name: 'English',
      text: (item: TranslationKey) => english.translations[item]?.singular.upper || '',
    },
    {
      key: 'custom',
      name: 'Custom',
      text: (item: TranslationKey) => customTranslation.translations[item]?.singular.upper || '',
    },
  ];

  return (
    <>
      {props.key ? (
        <CustomTranslationRecordModal
          open={props.open}
          setOpen={open => setProps({ ...props, open })}
          customTranslation={customTranslation}
          translationKey={props.key}
        />
      ) : null}
      <FlexPane
        header={<SearchBarWithFilter items={TranslationUtils.allKeys} setItems={setItems} filter={translationFilter} />}
        content={<Table headers={headers} items={items} onClick={item => setProps({ open: true, key: item })} />}
      />
    </>
  );
}

interface CreateCustomTranslationViewProps {
  setError: (error: string) => void;
}

function CreateCustomTranslationView({ setError }: CreateCustomTranslationViewProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { customTranslations, setCustomTranslations } = useContext(CustomTranslationContext);

  const [name, setName] = useState('');

  const [create, { loading }] = useMutation<CreateCustomTranslationResponse, CreateCustomTranslationVariables>(
    CustomTranslationMutations.create,
    {
      onCompleted: response => {
        setCustomTranslations(
          new Map(customTranslations).set(
            response.createCustomTranslation.id,
            new CustomTranslation(response.createCustomTranslation),
          ),
        );
      },
      onError: error => setError(error.message),
    },
  );

  const handleCreate = async () => {
    await create({
      variables: {
        customTranslation: new CustomTranslation({
          name: name,
          companyId: currentCompany.id,
          translations: JSON.stringify({}),
        }),
      },
    });
  };

  return (
    <Paper>
      <Grid container alignContent={'center'} rowSpacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <TextInput value={name} onChange={value => setName(value)} label={t().name.singular.label} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <CreateButton onClick={handleCreate} loading={loading} disabled={!name} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
